'use strict'

var ModuleSwiper = function ModuleSwiper(){}

import Swiper from 'swiper/js/swiper.min.js';

$(function() {

  /*
  ** Top用スライダー
  */
  var swiper = new Swiper('.site_top .swiper-container', {
    spaceBetween: 0,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 800,
  });

});

export default ModuleSwiper;
