'use strict'

var confetti = function confetti(){}


$(function () {
  let COLORS, Confetti, NUM_CONFETTI, PI_2, canvas, confetti, context, drawCircle, i, range, resizeWindow, xpos;

  NUM_CONFETTI = 160;

  COLORS = [
    [229, 229, 229],
    [157, 99, 217],
    [108, 166, 221],
    [109, 87, 215],
    [97, 154, 217],
    [191, 221, 107],
    [221, 200, 110],
    [197, 97, 50],
    [212, 70, 108]
  ];

  PI_2 = 2 * Math.PI;

  canvas = document.getElementById("confetti");
  if (canvas == null) {
    return;
  }
  context = canvas.getContext("2d");
  window.w = 0;
  window.h = 0;

  resizeWindow = function () {
    window.w = canvas.width = window.innerWidth;
    return window.h = canvas.height = window.innerHeight;
  };

  window.addEventListener('resize', resizeWindow, false);

  window.onload = function () {
    return setTimeout(resizeWindow, 0);
  };

  range = function (a, b) {
    return (b - a) * Math.random() + a;
  };

  drawCircle = function (x, y, w, h, style) {
    if (x > 10) {
      context.beginPath();
      context.fillRect(x, y, w, h);
      context.fillStyle = style;
      return context.fill();
    }
  };

  xpos = 0.5;

  window.requestAnimationFrame = function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
      return window.setTimeout(callback, 1000 / 60);
    };
  }();

  Confetti = class Confetti {
    constructor() {
      this.style = COLORS[~~range(0, 9)];
      this.rgb = `rgba(${this.style[0]},${this.style[1]},${this.style[2]}`;
      this.r = ~~range(2, 6);
      this.w = ~~range(10, 15);
      this.h = ~~range(10, 15);
      this.r2 = 2 * this.r;
      this.replace();
      this.speed = 0.95;
    }

    replace() {
      this.opacity = 0;
      this.dop = 0.03 * range(1, 4);
      this.x = range(-10, w - this.r2);
      this.y = range(-this.r2, h - this.r2);
      this.xmax = w - this.r;
      this.ymax = h - this.r;
      this.vx = range(0, 2) + 8 * xpos - 5;
      return this.vy = this.speed * this.r + range(-1, 1);
    }

    draw() {
      var ref;
      this.x += this.vx;
      this.y += this.vy;
      this.opacity += this.dop;
      if (this.opacity > 1) {
        this.opacity = 1;
        this.dop *= -1;
      }
      if (this.opacity < 0 || this.y > this.ymax) {
        this.replace();
      }
      if (!(0 < (ref = this.x) && ref < this.xmax)) {
        this.x = range(0, this.xmax);
      }
      return drawCircle(~~this.x, ~~this.y, this.w, this.h, `${this.rgb}, ${this.opacity})`);
    }
  };



  confetti = function () {
    var j, ref, results;
    results = [];
    for (i = j = 1, ref = NUM_CONFETTI; 1 <= ref ? j <= ref : j >= ref; i = 1 <= ref ? ++j : --j) {
      results.push(new Confetti());
    }
    return results;
  }();

  window.step = function () {
    var c, j, len, results;
    requestAnimationFrame(step);
    context.clearRect(0, 0, w, h);
    results = [];
    for (j = 0, len = confetti.length; j < len; j++) {
      c = confetti[j];
      results.push(c.draw());
    }
    return results;
  };

  step();

});

export default confetti;