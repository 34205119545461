'use strict'

var ModuleValidate = function ModuleValidate(){}

var validate = require('jquery-validation');

$(function() {
  console.log('Validate!');

  /*
  ** 生年月日入力 存在しない日はdisabled
  */
  $('[name="birthday_y"]').on('change', function() {
    checkBirthSelect($('[name="birthday_m"]').val());
  });

  $('[name="birthday_m"]').on('change', function() {
    checkBirthSelect($(this).val());
  });

  var checkBirthSelect = function(now) {
    var year   = $('[name="birthday_y"]').val();
    var month  = now;
    var date   = $('[name="birthday_d"]').val();
    var date29 = $('[name="birthday_d"] option[value="29"]');
    var date30 = $('[name="birthday_d"] option[value="30"]');
    var date31 = $('[name="birthday_d"] option[value="31"]');
    switch(month) {
      case '2':
        date30.prop('disabled', true);
        date31.prop('disabled', true);
        //うるう年を確認
        if(((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) && year != '') {
          console.log("うるう年")
          date29.prop('disabled', false);
          if(date == '30' || date == '31') {
            $('[name="birthday_d"]').val('29');
          }
        } else {
          console.log("通常のとし")
          date29.prop('disabled', true);
          if(date == '29' || date == '30' || date == '31') {
            $('[name="birthday_d"]').val('28');
          }
        }
      break;
      case '4':
        date29.prop('disabled', false);
        date30.prop('disabled', false);
        date31.prop('disabled', true);
        if(date == '31') {
          $('[name="birthday_d"]').val('30')
        }
      break;
      case '6':
        date29.prop('disabled', false);
        date30.prop('disabled', false);
        date31.prop('disabled', true);
        if(date == '31') {
          $('[name="birthday_d"]').val('30')
        }
      break;
      case '9':
        date29.prop('disabled', false);
        date30.prop('disabled', false);
        date31.prop('disabled', true);
        if(date == '31') {
          $('[name="birthday_d"]').val('30')
        }
      break;
      case '11':
        date29.prop('disabled', false);
        date30.prop('disabled', false);
        date31.prop('disabled', true);
        if(date == '31') {
          $('[name="birthday_d"]').val('30')
        }
      break;
      default:
        date29.prop('disabled', false);
        date30.prop('disabled', false);
        date31.prop('disabled', false);
      break;
    }
  };

  /*
  ** PINチェック なしの場合は入力値を消す
  */
  $('[name="pin_check"]').on('change', function() {
    var val = $(this).val();
    if(val == '2') {
      $('#pin_check_view [name="pin"]').val('');
    }
  });

  //共通メッセージ
  $.extend($.validator.messages, {
    required: '*必須項目です',
    isValZero: '*必須項目です',
    // mail: '*正しいログインID（メールアドレス）でご入力ください',
    email: '*正しいログインID（メールアドレス）でご入力ください',
    email_check : '*正しいログインID（メールアドレス）でご入力ください',//文言逆転バージョン
    isPhone: "*正しい電話番号の形式で入力してください",
    isZip: "*正しい郵便番号でご入力ください。",
    isPassword: "*正しいパスワードでご入力ください",
    isNewPassword: "*正しいパスワードでご入力ください",
    isKatakana: "*全角カナでご入力ください",
    isAlphanum: "*半角英数字でご入力ください",
    isEmoji: "*絵文字は使用できません",
    size8: "8文字でご入力ください"
  });

  //追加ルール （電話番号）
  var methods = {
    email_check: function( value, element ) {
      var target = $(element).attr('data-email');
      if(target = 'pre_mail') {
        $.validator.messages.email_check = '正しいメールアドレス（ログインID）でご入力ください'
      }
      return this.optional( element ) || /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test( value );
    },
    isValZero: function(value, element){
      return this.optional(element) || /[^0]/.test(value);
    },
    isPhone: function(value, element){
      return this.optional(element) || /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(value);
    },
    isZip: function(value, element){
      return this.optional( element ) || /^\d{7}$/.test(value);
    },
    isPassword: function(value, element){
        var counter=0;
        if(/([a-z])/s.test(value)){
            counter++;
        }
        if(/([A-Z])/s.test(value)){
            counter++;
        }
        if(/([0-9])/s.test(value)){
            counter++;
        }
        if(/([!#$%&()*+-./:;<=>?@[\]^_`{|}~｡｢｣､･ﾞﾟ])/s.test(value)){
            counter++;
        }

      return this.optional( element )
      || /([0-9].*[a-zA-Z]|[a-zA-Z].*[0-9])/s.test(value)
      ||(counter >= 3)
    },
    isNewPassword: function(value, element){
        var counter=0;
        if(/([a-z])/s.test(value)){
            counter++;
        }
        if(/([A-Z])/s.test(value)){
            counter++;
        }
        if(/([0-9])/s.test(value)){
            counter++;
        }
        if(/([!#$%&()*+-./:;<=>?@[\]^_`{|}~｡｢｣､･ﾞﾟ])/s.test(value)){
            counter++;
        }

        return this.optional( element ) || (counter >= 3)
    },
    isKatakana: function(value, element){
      return this.optional( element ) || /^[ア-ン゛゜ァ-ォャ-ョー]+$/u.test(value);
    },
    isAlphanum: function(value, element){
      return this.optional(element) || /^([a-zA-Z0-9]+)$/.test(value);
    },
    isEmoji: function(value, element){
      var arr = [];
      for (let str of Array.from(value)) {
        if (encodeURIComponent(str).replace(/%../g, "x").length < 4) {
          arr.push('1');
        } else {
          arr.push('0');
        }
      }
      if(arr.indexOf('0') != -1) {
        return this.optional(element);
      } else {
        return true;
      }
    },
    size8: function(value, element){
      return this.optional( element ) || /^.{8}$/.test(value);
    },
  };

  //メソッドの追加
  $.each(methods, function(key) {
    $.validator.addMethod(key, this);
  });

  $.validator.prototype.checkForm = function() {
    this.prepareForm();
    for (var i = 0, elements = (this.currentElements = this.elements()); elements[i]; i++) {
      if (this.findByName(elements[i].name).length != undefined && this.findByName(elements[i].name).length > 1) {
        for (var cnt = 0; cnt < this.findByName(elements[i].name).length; cnt++) {
          this.check(this.findByName(elements[i].name)[cnt]);
        }
      } else {
        this.check(elements[i]);
      }
    }
    return this.valid();
  }

  //チェックボックス agree
  $('input[name="agree"]').on('change', function() {
    var submit = $('button[type=submit]');
    if (submit.closest('form').valid()) {
      submit.show();
    } else {
      submit.hide();
    };

    if($(this).prop('checked') == true) {
      $(this).closest('.agree').removeClass('agree_err');
    }
  });

  //radio
  $('input[name="card_flag"]').on('change', function() {
    var flg = $('input[name="card_flag"]:checked').val();
    if(flg == '2') {
      $('.stripe_wrap').hide()
      $('[name="stripe_id1"]').prop('disabled', true);
      $('[name="stripe_id2"]').prop('disabled', true);
      $('[name="stripe_id3"]').prop('disabled', true);
    } else {
      $('.stripe_wrap').show()
      $('[name="stripe_id1"]').prop('disabled', false);
      $('[name="stripe_id2"]').prop('disabled', false);
      $('[name="stripe_id3"]').prop('disabled', false);
    }
  });

  //会員番号
  $('[name="stripe_id1"]').on('change', function() {
    var val = $(this).val();
    if(val.length == 2) {
      $('[name="stripe_id3"]').prop('disabled', true);
      $('[name="stripe_id3"]').hide();
      $('[name="stripe_id3"]').parents('.input_error_wrap').prev().hide();
      $('#stripe_id3-error').hide();
    } else {
      $('[name="stripe_id3"]').prop('disabled', false);
      $('[name="stripe_id3"]').show();
      $('[name="stripe_id3"]').parents('.input_error_wrap').prev().show();
    }
  });

  //validate
  if($('#form').length) {
    //共通処理
    $('input,textarea,select').on('input keyup blur', function() {
      var submit = $('button[type=submit]');
      if (submit.closest('form').valid()) {
        submit.show();
      } else {
        submit.hide();
      }
    });
    $('#form').validate({
      rules: {
        name: {
          required: true,
        },
        name1: {
          required: true,
        },
        name2: {
          required: true,
        },
        name_sei: {
          required: true,
          isEmoji: true,
          maxlength: 20,
        },
        name_mei: {
          required: true,
          isEmoji: true,
          maxlength: 20,
        },
        kana: {
          required: true,
          isKatakana: true,
        },
        kana1: {
          required: true,
          isKatakana: true,
        },
        kana2: {
          required: true,
          isKatakana: true,
        },
        name_kana_sei: {
          required: true,
          isKatakana: true,
          maxlength: 20,
        },
        name_kana_mei: {
          required: true,
          isKatakana: true,
          maxlength: 20,
        },
        gender: {
          required: true,
        },
        card_flag: {
          required: true,
        },
        tel: {
          required: true,
          isPhone: true,
        },
        tel_no: {
          required: true,
          isPhone: true,
        },
        zip_cd: {
          required: true,
          isZip: true,
        },
        pref_cd: {
          required: true,
        },
        address1: {
          required: true,
        },
        address_1: {
          required: true,
        },
        address2: {
          required: true,
        },
        address_2: {
          required: true,
        },
        // address3: {
        //   required: true,
        // },
        mail: {
          required: true,
          email_check: true
        },
        mail_c: {
          required: true,
          equalTo: "#mail",
        },
        email: {
          required: true,
          email_check: true
        },
        email_c: {
          required: true,
          equalTo: "#email",
        },
        num: {
          required: true,
          minlength: 4,
          maxlength: 4
        },
        stripe_id: {
          required: true,
        },
        stripe_id1: {
          required: true,
        },
        stripe_id2: {
          required: true,
          maxlength: 7
        },
        stripe_id3: {
          required: true,
          maxlength: 4
        },
        pin: {
          required: true,
          maxlength: 6,
        },
        birthday_y: {
          required: true,
        },
        birthday_m: {
          required: true,
        },
        birthday_d: {
          required: true,
        },
        post_no: {
          required: true,
          isZip: true,
        },
        oldPassword: {
            required: true,
            minlength: 8,
            maxlength: 20,
            isPassword: true,
          },
        password: {
          required: true,
          minlength: 8,
          maxlength: 20,
          isNewPassword: true,
        },
        password_c: {
          required: true,
          equalTo: "#password",
        },
        new_password: {
          required: true,
          minlength: 8,
          maxlength: 20,
          isNewPassword: true,
        },
        new_password_c: {
          required: true,
          equalTo: "#new_password",
          maxlength: 20,
        },
        category: {
          required: true,
        },
        inquiry_text: {
          required: true,
        },
        dm_flag: {
          required: true,
        },
        dm_flg1: {
          required: true,
        },
        agree: {
          required: true,
        },
        answer_0: {
          required: true,
        },
        answer_1_0: {
          required: true,
        },
        answer_1_1: {
          required: true,
        },
        answer_1_2: {
          required: true,
        },
        answer_1_3: {
          required: true,
        },
        answer_1_4: {
          required: true,
        },
        answer_1_5: {
          required: true,
        },
        answer_1_6: {
          required: true,
        },
        answer_1_7: {
          required: true,
        },
        answer_1_8: {
          required: true,
        },
        answer_1_9: {
          required: true,
        },
        answer_1_10: {
          required: true,
        },
        'code[]': {
          number: true,
        }
      },
      messages: {
        name_sei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_mei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_kana_sei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_kana_mei: {
          maxlength: "*20文字以下でご入力ください",
        },
        oldPassword: {
            minlength: "*8文字以上でご入力ください",
            maxlength: "*20文字以下でご入力ください",
          },
        password: {
          minlength: "*8文字以上でご入力ください",
          maxlength: "*20文字以下でご入力ください",
        },
        password_c: {
          equalTo: "*確認のパスワードが正しくありません",
          maxlength: "*20文字以下でご入力ください",
        },
        new_password: {
          minlength: "*8文字以上でご入力ください",
          maxlength: "*20文字以下でご入力ください",
        },
        new_password_c: {
          equalTo: "*確認のパスワードが正しくありません",
        },
        mail_c: {
          equalTo: "*確認のメールアドレスが正しくありません",
        },
        email_c: {
          equalTo: "*確認のメールアドレスが正しくありません",
        },
        num: {
          minlength: "*4文字でご入力ください",
          maxlength: "*4文字でご入力ください"
        },
        stripe_id2: {
          maxlength: "*4文字以下でご入力ください"
        },
        stripe_id3: {
          maxlength: "*7文字以下でご入力ください"
        },
        pin: {
          maxlength: "*6文字以下でご入力ください"
        },
        'code[]': {
          number: "*半角数字でご入力ください"
        }
      },
      errorElement: 'span',
      errorClass: 'valid_error',
      errorContainer: "#submit_btn_err",
      invalidHandler: function () {
        $('#form button[type="submit"]').hide();
      },
      errorPlacement: function(error, element){

        //requireでは初期エラーメッセージを出さない
        if(element.text() == '' || element.val() == '') {
          error.fadeOut(10);
        };

        if (element.is(':radio')) {
          //ラジオ
          // element.wrap('<div class="input_error_wrap"></div>');
          error.appendTo(element.closest('.radio_wrap')).addClass('tooltip');
          var w = element.closest('.radio_wrap').outerWidth();
        } else if(element.attr('name') == 'agree') {
          //チェックボックス agree
          element.closest('.agree').addClass('agree_err')
        } else {
          // element.wrap('<div class="input_error_wrap"></div>');
          error.insertAfter(element).addClass('tooltip');
          var w = element.outerWidth();
        };

        var mql = window.matchMedia('screen and (max-width: 600px)');
        if (mql.matches) {
          error.css({
            'left' : 'auto',
            'right': 0,
            'margin': 0,
          });
        } else {
          error.css({'left' : w - 25 + 'px'});
        };
      },
    });
    $('#form').valid();
  };

  //validate card
  if($('#form_card').length) {
    $('input,textarea').on('input keyup blur', function() {
      var submit = $('button[type=submit]');
      if (submit.closest('form').valid()) {
        submit.show();
      } else {
        submit.hide();
      }
    });
    $('input[name="pin_check"]').on('change', function() {
      if($(this).val() == '1') {
        $('#pin_check_view, #pin_check_view input').css({"display":"block"});
      } else {
        $('#pin_check_view, #pin_check_view input').css({"display":"none"});
      };
      var submit = $('button[type=submit]');
      if (submit.closest('form').valid()) {
        submit.show();
      } else {
        submit.hide();
      }
    });
    $('#form_card').validate({
      rules: {
        name_sei: {
          required: true,
          isEmoji: true,
          maxlength: 20,
        },
        name_mei: {
          required: true,
          isEmoji: true,
          maxlength: 20,
        },
        stripe_id: {
          required: true,
        },
        pin: {
          required: true,
          maxlength: 6,
        },
        pin_check : {
          required: true,
        },
        tel: {
          required: true,
          isPhone: true,
        },
        entry_code: {
          size8: 8,
        },
        agree: {
          required: true,
        }
      },
      messages: {
        name_sei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_mei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_kana_sei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_kana_mei: {
          maxlength: "*20文字以下でご入力ください",
        },
        pin: {
          maxlength: "*6文字以下でご入力ください"
        },
      },
      errorElement: 'span',
      errorClass: 'valid_error',
      errorContainer: "#submit_btn_err",
      invalidHandler: function () {
        $('#form_card button[type="submit"]').hide();
      },
      errorPlacement: function(error, element){

        //requireでは初期エラーメッセージを出さない
        if(element.text() == '' || element.val() == '') {
          error.fadeOut(10);
        };

        if (element.is(':radio')) {
          //ラジオ
          // element.wrap('<div class="input_error_wrap"></div>');
          error.appendTo(element.closest('.radio_wrap')).addClass('tooltip');
          var w = element.closest('.radio_wrap').outerWidth();
        } else if(element.attr('name') == 'agree') {
          //チェックボックス agree
          element.closest('.agree').addClass('agree_err')
        } else {
          // element.wrap('<div class="input_error_wrap"></div>');
          error.insertAfter(element).addClass('tooltip');
          var w = element.outerWidth();
        };

        var mql = window.matchMedia('screen and (max-width: 600px)');
        if (mql.matches) {
          error.css({
            'left' : 'auto',
            'right': 0,
            'margin': 0,
          });
        } else {
          error.css({'left' : w - 25 + 'px'});
        };
      },
    });
    $('#form_card').valid();
  };

  //validate member input WV only
  if($('#form_member').length) {
    //ダイレクトメールの有無で住所欄を出し入れ
    var dm = $('input[name="dm_flag"]').prop('checked');
    if(dm) {
      $('#input_address_group').addClass('dm_show');
    } else {
      $('#input_address_group').removeClass('dm_show');
    }

    $('input[name="dm_flag"]').on('change', function() {
      var value = $(this).val();
      if(value == 1) {
        $('#input_address_group').addClass('dm_show');
      } else {
        $('#input_address_group').removeClass('dm_show');
      };

      //発火
      var submit = $('button[type=submit]');
      if (submit.closest('form').valid()) {
        submit.show();
      } else {
        submit.hide();
      };
    });

    //共通処理
    $('input,textarea').on('input keyup blur', function() {
      var submit = $('button[type=submit]');
      if (submit.closest('form').valid()) {
        submit.show();
      } else {
        submit.hide();
      }
    });
    $('#form_member').validate({
      rules: {
        name_sei: {
          required: true,
          isEmoji: true,
          maxlength: 20,
        },
        name_mei: {
          required: true,
          isEmoji: true,
          maxlength: 20,
        },
        name_kana_sei: {
          required: true,
          isKatakana: true,
          maxlength: 20,
        },
        name_kana_mei: {
          required: true,
          isKatakana: true,
          maxlength: 20,
        },
        // gender: {
        //   required: true,
        // },
        mail: {
          required: true,
          email_check: true
        },
        birthday_y: {
          required: true,
        },
        post_no: {
          required: true,
          isZip: true,
        },
        address_1: {
          // required: true,
          isValZero: true,
        },
        address_2: {
          required: true,
        },
        address_3: {
          required: true,
        },
        password: {
          required: true,
          minlength: 8,
          maxlength: 20,
          isNewPassword: true,
        },
        password_c: {
          required: true,
          equalTo: "#password",
          maxlength: 20,
        },
        tel: {
          required: true,
          isPhone: true,
        },
        agree: {
          required: true,
        },
        'code[]': {
          number: true,
        }
      },
      messages: {
        name_sei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_mei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_kana_sei: {
          maxlength: "*20文字以下でご入力ください",
        },
        name_kana_mei: {
          maxlength: "*20文字以下でご入力ください",
        },
        password: {
          minlength: "*8文字以上でご入力ください",
          maxlength: "*20文字以下でご入力ください",
        },
        password_c: {
          equalTo: "*確認のパスワードが正しくありません",
          maxlength: "*20文字以下でご入力ください",
        },
        'code[]': {
          number: "*半角数字でご入力ください"
        }
      },
      errorElement: 'span',
      errorClass: 'valid_error',
      errorContainer: "#submit_btn_err",
      invalidHandler: function () {
        $('#form_member button[type="submit"]').hide();
      },
      errorPlacement: function(error, element){

        //requireでは初期エラーメッセージを出さない
        if(element.text() == '' || element.val() == '') {
          error.fadeOut(10);
        };

        if (element.is(':radio')) {
          //ラジオ
          // element.wrap('<div class="input_error_wrap"></div>');
          error.appendTo(element.closest('.radio_wrap')).addClass('tooltip');
          var w = element.closest('.radio_wrap').outerWidth();
        } else if(element.attr('name') == 'agree') {
          //チェックボックス agree
          element.closest('.agree').addClass('agree_err')
        } else {
          // element.wrap('<div class="input_error_wrap"></div>');
          error.insertAfter(element).addClass('tooltip');
          var w = element.outerWidth();
        };

        var mql = window.matchMedia('screen and (max-width: 600px)');
        if (mql.matches) {
          error.css({
            'left' : 'auto',
            'right': 0,
            'margin': 0,
          });
        } else {
          error.css({'left' : w - 25 + 'px'});
        };
      },
    });
    $('#form_member').valid();
  };
});

module.exports = ModuleValidate;
