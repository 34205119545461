'use strict'

import '../styles/variables.scss';
import '../styles/foundation.scss';
import '../styles/layout.scss';
import '../styles/swiper.scss';
import '../styles/button.scss';
import '../styles/form.scss';
import '../styles/project.scss';
import '../styles/component.scss';
import '../styles/utility.scss';

var confetti = require('./modules/confetti');
var General  = require('./modules/ModuleGeneral');
var Swiper   = require('./modules/ModuleSwiper');
var validate = require('./modules/ModuleValidate');
